import React from "react";
import LocationSlider from "./component/LocationSlider";

const Location = () => {
  return (
    <>
      <div className="properties-grids-section pt-[80px] pb-[120px] lg:py-[120px]">
        <div className="container">
          <div className="max-w-[460px] mx-auto">
            <h2 className=" font-lora text-primary text-[24px] sm:text-[30px] leading-[1.277] xl:text-xl capitalize mb-3 font-medium">
              our<span className="text-secondary"> Location</span>
            </h2>
          </div>
          <div className="columns-1 sm:columns-2 md:columns-3 gap-x-[30px] mb-[-30px]">
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Gachibowli.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Homes"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Gachibowli
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Kondapur.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Villa"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Kondapur
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Kukatpally.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Appartments"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Kukatpally
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Madhapur.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Houses"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Madhapur
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Hi-tech city.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="duplex house"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Hi-tech city
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Miyapur.png"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Miyapur
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Manikonda.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Manikonda
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
