import React from "react";
import HomeBanner from "./home/HomeBanner";
import Location from "./home/Location";
import BrandingCompanies from "./home/BrandingCompanies";
import Mission from "./home/Mission";
import TypeSales from "./TypeSales";
import Testimonial from "./home/Testimonial";
import HomeAbout from "./home/HomeAbout";
import HomeVideo from "./home/HomeVideo";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <Mission />
      <HomeAbout />
      <Location />
      <HomeVideo />
      <TypeSales />
      <BrandingCompanies />
      <Testimonial />
    </>
  );
};

export default Home;
