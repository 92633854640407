import React from "react";
import TypeSales from "../TypeSales";
import HomeVideo from "../home/HomeVideo";
import BrandingCompanies from "../home/BrandingCompanies";

const About = () => {
  return (
    <>
      <section
        className="bg-no-repeat bg-center bg-cover bg-[#E9F1FF] h-[350px] lg:h-[513px] flex flex-wrap items-center relative before:absolute before:inset-0 before:content-[''] before:bg-[#000000] before:opacity-[70%]"
        style={{
          backgroundImage: 'url("assets/images/breadcrumb/bg-1.png")',
        }}
      >
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="max-w-[700px]  mx-auto text-center text-white relative z-[1]">
                <h1 className="font-lora text-[32px] sm:text-[50px] md:text-[68px] lg:text-[50px] leading-tight xl:text-2xl font-medium">
                  About Us
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
      <section className="pt-[80px] lg:pt-[120px]">
        <div className="container">
          <div className="grid grid-cols-12 gap-x-[15px] sm:gap-x-[20px] mb-[-40px]">
            <div
              className="col-span-12 lg:col-span-6 mb-[40px] scene"
              data-relative-input="true"
            >
              <img
                data-depth="0.1"
                className="w-full rounded-[6px]"
                src="assets/images/about/about-bg.png"
                loading="lazy"
                width={611}
                height={375}
                alt="about image"
              />
            </div>
            <div className="col-span-12 lg:col-span-6 xl:pl-[100px] mb-[40px]">
              <h2 className="font-lora text-primary text-[24px] sm:text-[30px] leading-[1.388] xl:text-[35px] capitalize mb-[15px] lg:mb-[50px] font-medium">
                About Us
                <span className="text-secondary"></span>
              </h2>
              <p class="max-w-[448px] ">
                Sree Vighneswara Realty  realised very early on, that success can
                be very quickly redefined. Sree Vighneswara Realty  has prepared
                itself fully to dare the challenges of tomorrow’s marketplace.
                Technology skills, domain expertise, process focus, and a
                commitment to long-term client relationship they all combine, at
                Sree Vighneswara Realty , to deliver performances that rank high
                on quality.
              </p>
              <br />
              <p class="max-w-[448px]">
                This corporate culture, of delivering value to the customer, is
                the outcome of the efforts and beliefs of Mr. Yoganand, Chairman
                & Managing Director, Sree Vighneswara Realty  Conglomerate. His
                philosophy of upholding the values of integrity, business
                ethics, and respect even as the team goes all out to achieve
                industry leadership, has set Sree Vighneswara Realty  in a niche
                of its own as a Conglomerate that puts customer delight before
                corporate gain. Its projects stand as hallmarks of quality
                construction, clear titles, and value for money.
              </p>
              <ul className="flex flex-wrap list-none">
                <li className="block">
                  <span className="font-lora text-secondary text-xl">
                    <span className="counter-up">20</span>
                    <span>+</span>
                  </span>
                  <p>Locations</p>
                </li>
                <li className="block pl-[30px] sm:pl-[40px] md:pl-[60px]">
                  <span className="font-lora text-secondary text-xl">
                    <span className="counter-up">15</span>
                    <span>+</span>
                  </span>
                  <p>Projects Done</p>
                </li>
                <li className="block pl-[30px] sm:pl-[40px] md:pl-[60px]">
                  <span className="font-lora text-secondary text-xl">
                    <span className="counter-up">16</span>
                    <span>+</span>
                  </span>
                  <p>Awards Win</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <HomeVideo />
      <BrandingCompanies />
    </>
  );
};

export default About;
