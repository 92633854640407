import React from "react";

const Commercial = () => {
  return (
    <>
      <section
        className="bg-no-repeat bg-center bg-cover bg-[#E9F1FF] h-[350px] lg:h-[513px] flex flex-wrap items-center relative before:absolute before:inset-0 before:content-[''] before:bg-[#000000] before:opacity-[70%]"
        style={{
          backgroundImage: 'url("assets/images/breadcrumb/bg-1.png")',
        }}
      >
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="max-w-[700px]  mx-auto text-center text-white relative z-[1]">
                <h1 className="font-lora text-[32px] sm:text-[50px] md:text-[68px] lg:text-[50px] leading-tight xl:text-2xl font-medium">
                  Commercial
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <section className="featured-properties py-[80px] lg:py-[120px]">
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12"></div>
            <div className="col-span-12 flex flex-wrap flex-col md:flex-row items-start justify-between mb-[50px]">
              <div className="mb-5 lg:mb-0">
                <h2 className="font-lora text-primary text-[24px] sm:text-[30px] xl:text-xl capitalize font-medium">
                  Commercial
                  <span className="text-secondary"></span>
                </h2>
              </div>
            </div>
            <div className="col-span-12">
              <div className="all-properties properties-tab-content active">
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-[30px]">
                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <a to="" className="block">
                          <img
                            src="assets/images/properties/Ready to Move Offices.jpg"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Elite Garden Resedence."
                          />
                        </a>
                        {/* <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">
                          Builder Share
                        </span> */}
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <a
                            to=""
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Ready to Move Offices Space
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <a to="" className="block">
                          <img
                            src="assets/images/properties/Bare Shell Office.jpg"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="De Parasiya Appartment."
                          />
                        </a>
                        {/* <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">
                          Landlord Share
                        </span> */}
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <a
                            to=""
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Bare Shell Office Space
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <a to="" className="block">
                          <img
                            src="assets/images/properties/shops & Retail.jpg"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Duplex de Villa"
                          />
                        </a>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <a
                            to=""
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Shops & Retail
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  {/* <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <a to="" className="block">
                          <img
                            src="assets/images/properties/agriculture.jpeg"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Orchid Casel de Paradise."
                          />
                        </a>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <a
                            to=""
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Agriculture/Farm Land
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <a href="properties-details.html" className="block">
                          <img
                            src="assets/images/properties/Resorts.jpg"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Orchid Casel de Paradise."
                          />
                        </a>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <a
                            href="properties-details.html"
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Hotel/Resorts
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <a to="" className="block">
                          <img
                            src="assets/images/properties/properties2.png"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Sinomen Studio Palace."
                          />
                        </a>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <a
                            to=""
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Farm Houses
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Commercial;
