import React from "react";

const Mission = () => {
  return (
    <>
      <section className="service-section">
        <div className="container">
          <div className="grid grid-cols-12 text-center">
            <div className="col-span-12 text-center mb-[55px]">
              <div className="max-w-[460px] mx-auto">
                <h2 className=" font-lora text-primary text-[24px] sm:text-[30px] leading-[1.277] xl:text-xl capitalize mb-3 font-medium">
                  Mission<span className="text-secondary"> & Vision</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="grid gap-x-[50px] grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mb-[-30px] xl:px-[35px]" style={{marginBottom:"40px"}}>
            <div className="relative p-[20px] xl:p-[30px] mb-[30px] bg-[#E8F1FF] hover:bg-[#FFF6F0] transition-all drop-shadow-[0px_10px_20px_rgba(0,0,0,0.15)] rounded-[10px] skew-left">
              <div className="flex flex-wrap justify-between">
                <span className="font-lora text-[28px] leading-none text-secondary mb-[10px] block">
                  01.
                </span>
                <img
                  className="self-start mb-[-20px] md:mb-[0] lg:mb-[-20px]"
                  src="assets/images/service-icon/chart.png"
                  alt="image"
                  width={50}
                  height={50}
                  loading="lazy"
                />
              </div>
              <h3 className="font-lora text-base text-primary mb-[5px]">
                Mission
              </h3>
              <p className="font-light text-[14px] max-w-[250px]">
                Sree Vighneswara Realty is a construction, real
                estate, hospitality, and infrastructure conglomerate with a
                strong lineage focusing on quality, innovation, value to
                stakeholders’ interests.
              </p>
            </div>
            <div className="relative p-[20px] xl:p-[30px] mb-[30px] hover:bg-[#FFF6F0] bg-[#FFF6F0] transition-all drop-shadow-[0px_10px_20px_rgba(0,0,0,0.15)] rounded-[10px]">
              <div className="flex flex-wrap justify-between">
                <span className="font-lora text-[28px] leading-none text-secondary mb-[10px] block">
                  02.
                </span>
                <img
                  className="self-start mb-[-20px] md:mb-[0] lg:mb-[-20px]"
                  src="assets/images/service-icon/note.png"
                  alt="image"
                  width={50}
                  height={50}
                  loading="lazy"
                />
              </div>
              <h3 className="font-lora text-base text-primary mb-[5px]">
                Vision
              </h3>
              <p className="font-light text-[14px] max-w-[250px]">
                <br />
                To be synonymous with life-enriching spaces & structures and to
                ascend to industry leader status.
              </p>
            </div>
            <div className="relative p-[20px] xl:p-[30px] mb-[30px] bg-[#E8F1FF] hover:bg-[#FFF6F0] transition-all drop-shadow-[0px_10px_20px_rgba(0,0,0,0.15)] rounded-[10px] skew-right">
              <div className="flex flex-wrap justify-between">
                <span className="font-lora text-[28px] leading-none text-secondary mb-[10px] block">
                  03.
                </span>
                <img
                  className="self-start mb-[-20px] md:mb-[0] lg:mb-[-20px]"
                  src="assets/images/service-icon/key.png"
                  alt="image"
                  width={50}
                  height={54}
                  loading="lazy"
                />
              </div>
              <h3 className="font-lora text-base text-primary mb-[5px]">
                Core Values
              </h3>
              <p className="font-light text-[14px] max-w-[250px]">
                Upholding honesty, transparency, and ethical conduct in all
                dealings with clients, colleagues, and stakeholders. Trust is
                crucial in the real estate business, and integrity is the
                foundation of that trust.
              </p>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
};

export default Mission;
