import React from "react";

const HomeAbout = () => {
  return (
    <>
      <section className="pt-[80px] lg:pt-[120px] bg1">
        <div className="container">
          <div className="grid grid-cols-12 gap-x-[15px] sm:gap-x-[20px] mb-[-40px]">
            <div
              className="col-span-12 lg:col-span-6 mb-[40px] scene"
              data-relative-input="true"
            >
              <img
                data-depth="0.1"
                className="w-full rounded-[6px]"
                src="assets/images/about/about1.webp"
                loading="lazy"
                width={611}
                height={375}
                alt="about image"
              />
            </div>
            <div className="col-span-12 lg:col-span-6 xl:pl-[100px] mb-[40px]">
              <h2
                className="font-lora  text-primary text-[24px] sm:text-[30px] leading-[1.388] xl:text-[35px] capitalize mb-[15px] lg:mb-[50px] font-medium"
                style={{ color: "white" }}
              >
                About
                <span className="text-secondary"></span>
              </h2>
              <p class="max-w-[448px] " style={{ color: "white" }}>
                Creating landmarks across the skyline of India, the teams at
                Sree Vighneswara Realty Pvt. Ltd. are happy to be “home makers”
                to more than 500 families in premier properties.
              </p>
              <br />
              <p class="max-w-[448px]" style={{ color: "white" }}>
                For over 4+ years Sumadhura’s signature of success is centered
                around these core values: The ability to deliver luxurious
                housing projects on-time, across hand-picked locations, at
                honest prices while ensuring that the quality of construction is
                impeccable.Our expertise in the acquisition of land, innovative
                architecture, carefully curated premium brands, tastefully
                appointed interiors and after sales service has been
                instrumental in earning a reputation of being forerunners in
                real estate development.
              </p>
              <ul className="flex flex-wrap list-none">
                <li className="block">
                  <span className="font-lora text-secondary text-xl">
                    <span className="counter-up text-white">20</span>
                    <span className="text-white">+</span>
                  </span>
                  <p className="text-white">Locations</p>
                </li>
                <li className="block pl-[30px] sm:pl-[40px] md:pl-[60px]">
                  <span className="font-lora text-secondary text-xl">
                    <span className="counter-up text-white">15</span>
                    <span className="text-white">+</span>
                  </span>
                  <p className="text-white">Projects Done</p>
                </li>
                <li className="block pl-[30px] sm:pl-[40px] md:pl-[60px]">
                  <span className="font-lora text-secondary text-xl">
                    <span className="counter-up text-white">16</span>
                    <span className="text-white">+</span>
                  </span>
                  <p className="text-white">Awards Win</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
