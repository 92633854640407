import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [mail, setmail] = useState("");
  const [massages, setMassages] = useState("");

  const nameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };
  const mobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);
  };
  const mailChange = (e) => {
    const value = e.target.value;
    setmail(value);
  };
  const massagesChange = (e) => {
    const value = e.target.value;
    setMassages(value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_96x59oe",
        "template_wa5w3bk",
        "#myForm",
        "inWYMs1SpewvoiICQ"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          if (response.text === "OK") {
            setName("");
            setMobile("");
            setmail("");
            setMassages("");
          }
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <>
      <div>
        <section
          className="bg-no-repeat bg-center bg-cover bg-[#E9F1FF] h-[350px] lg:h-[513px] flex flex-wrap items-center relative before:absolute before:inset-0 before:content-[''] before:bg-[#000000] before:opacity-[70%]"
          style={{
            backgroundImage: 'url("assets/images/breadcrumb/bg-1.png")',
          }}
        >
          <div className="container">
            <div className="grid grid-cols-12">
              <div className="col-span-12">
                <div className="max-w-[700px]  mx-auto text-center text-white relative z-[1]">
                  <h1 className="font-lora text-[32px] sm:text-[50px] md:text-[68px] lg:text-[50px] leading-tight xl:text-2xl font-medium">
                    Contact Us
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="py-[80px] lg:py-[120px]">
          <div class="container">
            <div class="grid gap-y-[30px] gap-x-[50px]  grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:px-[35px]">
              <div class="relative py-[50px] px-[20px] lg:px-[35px] bg-[#F5F9F8] transition-all drop-shadow-[0px_10px_20px_rgba(0,0,0,0.15)] rounded-[10px] skew-left">
                <span class="font-lora text-[28px] leading-none text-secondary mb-[15px] block">
                  Email
                </span>

                <a
                  href="mailto:care@sreevighneswararealty.com"
                  class="font-lora text-base text-primary hover:text-secondary"
                >
                  care@sreevighneswararealty.com
                </a>
                <svg
                  class="absolute top-1/2 -translate-y-1/2 right-[30px]"
                  width="90"
                  height="91"
                  viewBox="0 0 90 91"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.05" clip-path="url(#clip0_1035_2387)">
                    <path
                      d="M63.7323 21.964C63.7482 19.4792 61.7431 17.4577 59.2586 17.4497C59.2506 17.4497 59.2426 17.4497 59.2347 17.4497C56.7662 17.4497 54.753 19.4472 54.745 21.924L54.7291 24.1851C51.5496 21.7403 47.6831 20.35 43.3692 20.35C32.7683 20.35 21.8079 29.5223 21.8079 44.8627C21.8079 58.8608 31.2824 70.2383 42.9378 70.2383C48.0026 70.2383 52.5881 68.105 56.143 64.5096C56.846 65.9398 57.7807 67.2581 58.9071 68.3926C61.4155 70.9094 64.643 72.2997 67.9982 72.2997C77.7124 72.2997 83.8636 65.5403 86.38 59.2044C90.2865 49.3769 90.9895 41.0915 88.6568 32.3507C88.6408 32.3028 88.6328 32.2548 88.6169 32.2069C85.0859 20.5018 77.1851 10.9221 66.3685 5.22537C55.5519 -0.471353 43.1775 -1.56595 31.5221 2.1493C20.4898 5.68079 11.2948 13.2631 5.65486 23.506C0.0148859 33.7569 -1.46301 45.5818 1.47681 56.7835C4.03317 66.4911 9.54533 74.8724 17.4221 81.0085C25.0033 86.921 34.4539 90.3166 44.0163 90.5803C44.0642 90.5803 44.1042 90.5803 44.1441 90.5803C46.5727 90.5803 48.5698 88.6468 48.6337 86.2099C48.7056 83.7251 46.7484 81.6637 44.2639 81.5918C28.2707 81.1523 14.2506 70.0066 10.1684 54.4904C7.8118 45.5179 9.0021 36.058 13.5317 27.8285C18.0532 19.607 25.4188 13.5268 34.2622 10.7144C43.6009 7.75014 53.5228 8.62902 62.1904 13.1912C70.8342 17.7454 77.1532 25.3916 79.9971 34.7397C81.8984 41.9385 80.756 49.0653 78.0399 55.8966C77.1532 58.1338 74.3971 63.3271 68.0062 63.3271C66.728 63.3271 65.7534 62.5441 65.29 62.0727C64.2036 60.9781 63.5485 59.3642 63.5565 57.7582L63.7323 21.964ZM42.9378 61.2578C37.0981 61.2578 30.7951 54.9938 30.7951 44.8627C30.7951 35.1391 37.186 29.3386 43.3692 29.3386C49.832 29.3386 54.3935 35.3389 54.6332 44.0238L54.6252 45.6697C54.2817 54.3466 49.177 61.2578 42.9378 61.2578Z"
                      fill="#FF6500"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1035_2387">
                      <rect width="90" height="90.5882" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <div class="relative py-[50px] px-[20px] lg:px-[35px] bg-[#F5F9F8] transition-all drop-shadow-[0px_10px_20px_rgba(0,0,0,0.15)] rounded-[10px]">
                <span class="font-lora text-[28px] leading-none text-secondary mb-[15px] block">
                  Whatsapp
                </span>
                <a
                  href="tel:+919948851333"
                  class="font-lora text-base text-primary hover:text-secondary"
                >
                  +91 9948851333
                </a>
                <svg
                  class="absolute top-1/2 -translate-y-1/2 right-[30px]"
                  width="90"
                  height="100"
                  viewBox="0 0 90 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.05" clip-path="url(#clip0_1035_2389)">
                    <path
                      d="M39.0114 62.0855C42.3982 65.4768 46.7536 67.0369 49.7794 67.0369L51.4999 67.0068C57.4915 66.9044 63.1281 64.507 67.3632 60.2664C68.9152 58.7183 68.452 56.4113 67.2489 55.5198L57.702 48.442C57.1245 48.0143 56.3124 48.0746 55.8071 48.5806L51.524 52.8694C50.6216 53.7729 50.369 55.0861 50.766 56.2185C49.2982 56.2788 47.8304 55.7186 46.7656 54.6524L35.6066 43.4906C34.5418 42.4244 33.9884 40.9607 34.0425 39.4849C35.1735 39.8824 36.4849 39.6294 37.3872 38.7259L41.6703 34.4371C42.1817 33.9251 42.2418 33.1179 41.8087 32.5396L34.7524 22.9741C33.8861 21.7995 31.5881 21.2815 30.012 22.8597C25.777 27.1063 23.3828 32.7444 23.2805 38.744L23.2505 40.4727C23.2444 40.7077 23.2264 46.2795 28.1953 51.255L39.0114 62.0855ZM26.1981 38.7982C26.2884 33.5576 28.3758 28.6303 32.0814 24.9258C32.2438 24.7631 32.3822 24.7571 32.5085 24.8354L38.7287 33.2625L35.3299 36.6658C35.2156 36.7803 35.0171 36.7803 34.9028 36.6658C33.7598 35.5213 31.7987 36.0574 31.3896 37.6175C30.6557 40.4426 31.4799 43.4906 33.5492 45.5567C41.55 53.5681 40.0341 52.0502 44.7022 56.7245C46.7716 58.7966 49.8095 59.6219 52.6308 58.887C54.1949 58.4774 54.7243 56.5137 53.5813 55.3692C53.467 55.2547 53.467 55.056 53.5813 54.9415L56.9802 51.5382L65.396 57.7666C65.4742 57.8931 65.4682 58.0316 65.3058 58.1943C61.6062 61.8988 56.6854 63.989 51.4518 64.0854L49.7433 64.1155C49.5629 64.1215 45.1354 64.0854 41.0748 60.0194L30.2587 49.1889C26.1981 45.123 26.168 40.6956 26.168 40.5209L26.1981 38.7982Z"
                      fill="#FF6500"
                    />
                    <path
                      d="M21.2816 86.7944C20.6921 88.0594 22.0516 89.3545 23.2848 88.7039C27.0747 86.7041 30.4976 84.4452 33.4452 81.9996C34.0047 81.5358 34.7566 81.3672 35.4605 81.5539C59.4388 87.8666 82.6772 69.6392 82.6772 45.117C82.6772 24.3415 65.7973 7.44519 45.0554 7.44519C24.3135 7.44519 7.43359 24.3475 7.43359 45.117C7.43359 57.3991 13.4432 68.9404 23.5074 76.0001C24.3015 76.5603 24.6504 77.5602 24.3797 78.4879C23.5014 81.4635 22.4607 84.2585 21.2816 86.7944ZM10.3512 45.117C10.3512 25.9558 25.9197 10.3606 45.0614 10.3606C64.1972 10.3606 79.7717 25.9498 79.7717 45.117C79.7717 67.7417 58.3259 84.5536 36.2124 78.7288C34.6002 78.3072 32.8737 78.6866 31.5984 79.7468C29.7877 81.2467 27.7845 82.6803 25.6129 84.0175C26.1843 82.5056 26.7077 80.9335 27.1829 79.3071C27.8086 77.1687 27.0085 74.8797 25.1857 73.6027C15.8916 67.0972 10.3512 56.4474 10.3512 45.117Z"
                      fill="#FF6500"
                    />
                    <path
                      d="M48.5721 90.1015C59.1957 89.2763 69.2298 84.6561 76.8216 77.0905C84.4133 69.5248 89.0754 59.5075 89.9597 48.8818C90.0259 48.0807 89.4304 47.3759 88.6303 47.3036C87.8242 47.2374 87.1264 47.8337 87.0542 48.6348C85.3638 69.0308 68.7245 85.6079 48.3495 87.1921C47.5495 87.2523 46.9479 87.9571 47.0081 88.7582C47.0742 89.5714 47.7781 90.1617 48.5721 90.1015Z"
                      fill="#FF6500"
                    />
                    <path
                      d="M90 41.8401C89.1819 30.5037 84.1408 19.9683 75.8031 12.1617C67.4233 4.31894 56.5049 0 45.0571 0C20.2126 0 0 20.2394 0 45.117C0 58.369 5.87728 71.1029 16.158 79.7287C14.7323 83.6863 12.9697 87.0836 10.9244 89.8424C9.28815 92.0471 9.29416 95.0047 10.9425 97.1973C12.6328 99.4441 15.4903 100.179 17.9807 99.2393C21.8067 97.7996 29.1759 94.6192 36.154 89.3425C37.9226 89.6979 39.7393 89.9508 41.55 90.0894C42.3561 90.1556 43.0539 89.5533 43.1141 88.7461C43.1743 87.945 42.5787 87.2402 41.7726 87.18C39.8657 87.0294 37.9587 86.7523 36.1059 86.3487C35.6848 86.2584 35.2456 86.3547 34.9027 86.6198C28.075 91.9085 20.7239 95.083 16.9521 96.5045C15.6166 97.0045 14.1428 96.6009 13.2705 95.4384C12.4043 94.2878 12.4043 92.7398 13.2645 91.5772C15.6587 88.3546 17.674 84.3609 19.2561 79.7107C19.4546 79.1264 19.2681 78.4818 18.7868 78.1024C8.69862 70.0427 2.91759 58.0196 2.91759 45.117C2.91759 21.8477 21.8247 2.91544 45.0632 2.91544C67.0503 2.91544 85.5123 20.1008 87.0944 42.0449C87.1546 42.8521 87.8524 43.4545 88.6525 43.3942C89.4526 43.34 90.0541 42.6413 90 41.8401Z"
                      fill="#FF6500"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1035_2389">
                      <rect width="90" height="99.6429" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <div class="relative py-[50px] px-[20px] lg:px-[35px] bg-[#F5F9F8] transition-all drop-shadow-[0px_10px_20px_rgba(0,0,0,0.15)] rounded-[10px] skew-right">
                <span class="font-lora text-[28px] leading-none text-secondary mb-[15px] block">
                  Address
                </span>
                <a
                  to="skype:amelia.margaret"
                  class="font-lora text-base text-primary hover:text-secondary"
                >
                  Sriramnagar Colony, Block C, Kondapur, Botanical Garden,
                  Hyderabad
                </a>
                <svg
                  class="absolute top-1/2 -translate-y-1/2 right-[30px] skype"
                  width="90"
                  height="102"
                  viewBox="0 0 90 102"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.05" clip-path="url(#clip0_1035_2394)">
                    <path
                      d="M66.3384 102C61.8961 102 57.5298 100.585 53.7782 97.9205C47.2129 99.4862 40.3625 99.2707 33.8859 97.2812C26.801 95.1051 20.2928 90.8246 15.0773 84.9065C9.86187 78.9885 6.08494 71.6268 4.15845 63.5973C2.40306 56.2572 2.21295 48.4933 3.60078 41.0527C0.907499 36.1832 -0.33458 30.4088 0.077334 24.6344C0.533608 18.307 2.96707 12.3531 6.93411 7.85709C10.9012 3.3611 16.1546 0.603182 21.7377 0.0860716C26.8327 -0.387946 31.9278 1.02693 36.2243 4.07213C42.7896 2.50643 49.6401 2.7219 56.1166 4.71134C63.2015 6.88751 69.7098 11.168 74.9252 17.0861C80.147 23.0041 83.9176 30.3729 85.8441 38.4025C87.5995 45.7426 87.7896 53.5064 86.4081 60.9471C89.0951 65.8165 90.3435 71.5909 89.9252 77.3653C89.4689 83.6927 87.0355 89.6467 83.0685 94.1427C79.1014 98.6387 73.8479 101.397 68.2649 101.914C67.6249 101.971 66.9785 102 66.3384 102ZM54.3232 91.435C54.8872 91.435 55.4449 91.629 55.9138 92.0096C63.1318 97.7625 72.91 96.8001 79.1584 89.7113C85.4069 82.6298 86.2624 71.5478 81.1863 63.3674C80.73 62.6277 80.5716 61.7012 80.7617 60.825C83.7972 46.5685 80.1534 31.874 71.0216 21.5174C61.8771 11.1608 48.9113 7.03833 36.3321 10.4786C35.559 10.6868 34.7415 10.5145 34.0887 9.99735C26.8707 4.23732 17.0989 5.2069 10.8441 12.2884C4.58937 19.37 3.7402 30.4519 8.82258 38.6323C9.27885 39.3721 9.43728 40.2986 9.24717 41.1748C6.21168 55.4312 9.85553 70.1258 18.9873 80.4824C28.1255 90.8389 41.0913 94.9686 53.6705 91.5212C53.8859 91.4638 54.1014 91.435 54.3232 91.435Z"
                      fill="#FF6500"
                    />
                    <path
                      d="M44.9983 77.6815C40.5686 77.6815 36.3671 76.2451 33.1732 73.6452C29.7068 70.8154 27.793 66.9587 27.793 62.7715C27.793 61.0406 29.0287 59.6329 30.5623 59.6329C32.0959 59.6329 33.3316 61.0334 33.3316 62.7715C33.3316 67.4542 38.6738 71.4115 45.0046 71.4115C51.3354 71.4115 56.6776 67.4542 56.6776 62.7715C56.6776 58.4622 50.106 55.9413 43.7499 53.5066C40.144 52.1276 36.4178 50.6984 33.5408 48.788C29.7321 46.2527 27.7993 43.0351 27.7993 39.2286C27.7993 35.0486 29.7068 31.1847 33.1795 28.3549C36.3735 25.7478 40.575 24.3186 45.0046 24.3186C49.4343 24.3186 53.6358 25.755 56.8297 28.3549C60.2961 31.1847 62.21 35.0415 62.21 39.2286C62.21 40.9595 60.9742 42.3672 59.4406 42.3672C57.907 42.3672 56.6713 40.9667 56.6713 39.2286C56.6713 34.5459 51.3291 30.5886 44.9983 30.5886C38.6675 30.5886 33.3253 34.5459 33.3253 39.2286C33.3253 42.8987 39.8018 45.3837 45.5116 47.5742C53.3316 50.5763 62.1973 53.9734 62.1973 62.7787C62.1973 66.9587 60.2898 70.8226 56.8171 73.6524C53.6295 76.2523 49.4343 77.6815 44.9983 77.6815Z"
                      fill="#FF6500"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1035_2394">
                      <rect width="90" height="102" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-[80px] lg:pt-[120px]">
          <div className="container">
            <div>
              <h2 className="font-lora text-[24px] sm:text-[28px] leading-[1.277] capitalize mb-[10px] font-medium">
                Send Message
              </h2>
              <br />
            </div>

            <form onSubmit={sendEmail} id="myForm">
              <div className="grid grid-cols-12 gap-x-[30px] mb-[-30px] items-end">
                <div className="col-span-12 lg:col-span-7 mb-[30px]">
                  <div className="grid grid-cols-12 gap-x-[20px] gap-y-[30px]">
                    <div className="col-span-12 md:col-span-12">
                      <input
                        className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] "
                        type="text"
                        placeholder="Full Name"
                        name="name"
                        value={name}
                        onChange={(e) => nameChange(e)}
                      />
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <input
                        className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] "
                        type="number"
                        placeholder="Phone number"
                        name="number"
                        maxLength={10}
                        minLength={10}
                        value={mobile}
                        onChange={(e) => mobileChange(e)}
                      />
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <input
                        className="font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] "
                        type="user_ email"
                        placeholder="Email Address"
                        name="user_ email"
                        value={mail}
                        onChange={(e) => mailChange(e)}
                      />
                    </div>
                    <div className="col-span-12">
                      <textarea
                        className="h-[196px] font-light w-full leading-[1.75] placeholder:opacity-100 placeholder:text-body border border-primary border-opacity-60 rounded-[8px] p-[15px] focus:border-secondary focus:border-opacity-60 focus:outline-none focus:drop-shadow-[0px_6px_15px_rgba(0,0,0,0.1)] resize-none"
                        name="message"
                        id="textarea"
                        cols={30}
                        rows={10}
                        placeholder="Message"
                        value={massages}
                        onChange={(e) => massagesChange(e)}
                      />
                    </div>

                    <div className="col-span-12 mb-[30px] lg:mb-0  order-2 lg:order-none">
                      <a href="">
                        <button
                          type="submit"
                          value="Send"
                          className="before:rounded-md before:block before:absolute before:left-auto before:right-0 before:inset-y-0 before:-z-[1] before:bg-secondary before:w-0 hover:before:w-full hover:before:left-0 hover:before:right-auto before:transition-all leading-none px-[30px] py-[15px] capitalize font-medium text-white text-[14px] xl:text-[16px] relative after:block after:absolute after:inset-0 after:-z-[2] after:bg-primary after:rounded-md after:transition-all mb-[30px] "
                        >
                          Submit
                        </button>
                      </a>
                      <p className="form-messege mt-3" />
                    </div>
                  </div>
                </div>

                <div className="col-span-12 lg:col-span-5 mb-[30px] order-last lg:order-none">
                  <div
                    className="col-span-12 lg:col-span-6 mb-[40px] scene"
                    data-relative-input="true"
                  >
                    <img
                      data-depth="0.1"
                      className="w-full rounded-[6px]"
                      src="assets/images/properties/EnquiryNow.png"
                      loading="lazy"
                      width={611}
                      height={375}
                      alt="about image"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <section className="py-[80px] lg:py-[120px]">
        <div className="container">
          <div className="grid col-span-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-[30px] mb-[-30px]">
            <div className="flex hover:drop-shadow-[0px_16px_10px_rgba(0,0,0,0.1)] hover:bg-[#F5F9F8] transition-all p-[20px] xl:p-[35px] rounded-[8px] mb-[30px] group">
              <img
                className="self-center mr-[20px] sm:mr-[40px] lg:mr-[20px] xl:mr-[40px] sm:mb-[15px] lg:mb-0"
                src="assets/images/icon/map.png"
                width={40}
                height={55}
                loading="lazy"
                alt="image icon"
              />
              <div className="flex-1">
                <h4 className="font-lora group-hover:text-secondary group-hover:transition-all leading-none text-[28px] text-primary mb-[10px]">
                  Address <span className="text-secondary"></span>
                </h4>
                <p className="font-light text-[18px] lg:max-w-[230px]">
                  Sriramnagar Colony, Block C, Kondapur, Botanical Garden,
                  Hyderabad
                </p>
              </div>
            </div>
            <div className="flex hover:drop-shadow-[0px_16px_10px_rgba(0,0,0,0.1)] hover:bg-[#F5F9F8] transition-all p-[20px] xl:p-[35px] rounded-[8px] mb-[30px] group">
              <img
                className="self-center mr-[20px] sm:mr-[40px] lg:mr-[20px] xl:mr-[40px] sm:mb-[15px] lg:mb-0"
                src="assets/images/icon/phone.png"
                width={46}
                height={46}
                loading="lazy"
                alt="image icon"
              />
              <div className="flex-1">
                <h4
                  className="font-lora group-hover:text-secondary group-hover:transition-all leading-none text-[28px] text-primary mb-[10px]"
                  style={{ fontSize: "22px" }}
                >
                  Call us <span className="text-secondary"> & Whatsapp</span>
                </h4>
                <p className="font-light text-[18px] lg:max-w-[230px]">
                  <a
                    href="tel:+919948851333"
                    class="font-lora text-base text-primary hover:text-secondary"
                  >
                    +91 9948851333
                  </a>
                </p>
              </div>
            </div>
            <div className="flex hover:drop-shadow-[0px_16px_10px_rgba(0,0,0,0.1)] hover:bg-[#F5F9F8] transition-all p-[20px] xl:p-[35px] rounded-[8px] mb-[30px] xl:pl-[65px] group">
              <img
                className="self-center mr-[20px] sm:mr-[40px] lg:mr-[20px] xl:mr-[40px] sm:mb-[15px] lg:mb-0"
                src="assets/images/icon/mail.png"
                width={46}
                height={52}
                loading="lazy"
                alt="image icon"
              />
              <div className="flex-1">
                <h4 className="font-lora group-hover:text-secondary group-hover:transition-all leading-none text-[28px] text-primary mb-[10px]">
                  Email us <span className="text-secondary"></span>
                </h4>
                <p className="font-light text-[18px] lg:max-w-[230px]">
                  <a
                    href="mailto:care@sreevighneswararealty.com"
                    className="hover:text-secondary"
                  >
                    care@sreevighneswararealty.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
