import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

export default function HomeBanner() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <section className="hero-section bg-white">
            <div className="hero-slider2 overflow-hidden">
              <div className="swiper">
                <div className="swiper-wrapper">
                  <div
                    className="swiper-slide bg-no-repeat bg-left-bottom bg-sky-100 z-[1] relative before:absolute before:w-full before:h-full before:inset-0 before:content-[''] before:bg-[#000000] before:opacity-[75%] before:z-[-1] py-[80px] lg:py-[125px]"
                    style={{
                      backgroundImage: 'url("assets/images/hero/slider1.jpg")',
                    }}
                  >
                    <div className="container">
                      <div className="grid grid-cols-12">
                        <div className="col-span-12">
                          <div className="slider-content">
                            <div className="relative mb-5 sub_title"></div>
                            <h1 className="font-lora text-white text-[36px] sm:text-[50px] md:text-[68px] lg:text-[50px] leading-tight xl:text-2xl title">
                              <span>
                                Sree Vighneswara Realty Pvt. Ltd. Zone
                              </span>
                            </h1>
                            <p className="text-base text-white font-light underline text">
                              welcome to the future
                            </p>
                            <Link to="/contact">
                              <span
                                className="text-white inline-block font-lora text-[18px] px-[20px] py-[12px] leading-none border-b-2 border-secondary bg-primary  mt-[40px] mb-[75px] hero_btn text-center"
                                style={{ marginLeft: "520px" }}
                              >
                                Explore
                              </span>
                            </Link>
                            <div className="col-span-12 xl:col-span-10">
                              <ul className="flex flex-wrap items-center sm:justify-between text-white mt-[-20px] mb-[10px] lg:mb-[60px]">
                                <li className="pr-[30px] sm:pr-[35px] lg:pr-[70px] sm:border-r sm:border-[#E0E0E0] my-[20px]"></li>
                                <li className="pr-[30px] sm:pr-[35px] lg:pr-[70px] sm:border-r sm:border-[#E0E0E0] my-[20px]"></li>
                                <li className="pr-[30px] sm:pr-[35px] lg:pr-[70px] sm:border-r sm:border-[#E0E0E0] my-[20px]"></li>
                                <li className="pr-[30px] sm:pr-[35px] lg:pr-[70px] sm:border-r sm:border-[#E0E0E0] my-[20px]"></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-pagination home-pagination-six hidden lg:flex flex-wrap flex-col items-end"></div>
              </div>
            </div>
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className="hero-section bg-white">
            <div className="hero-slider2 overflow-hidden">
              <div className="swiper">
                <div className="swiper-wrapper">
                  <div
                    className="swiper-slide bg-no-repeat bg-left-bottom bg-sky-100 z-[1] relative before:absolute before:w-full before:h-full before:inset-0 before:content-[''] before:bg-[#000000] before:opacity-[75%] before:z-[-1] py-[80px] lg:py-[125px]"
                    style={{
                      backgroundImage: 'url("assets/images/hero/home-3.jpg")',
                    }}
                  >
                    <div className="container">
                      <div className="grid grid-cols-12">
                        <div className="col-span-12">
                          <div className="slider-content">
                            <div className="relative mb-5 sub_title"></div>
                            <h1 className="font-lora text-white text-[36px] sm:text-[50px] md:text-[68px] lg:text-[50px] leading-tight xl:text-2xl title">
                              <span>Luxury 2, 3 & 4 BHK Apartments</span>
                            </h1>
                            <p className="text-base text-white font-light underline text">
                              welcome to the future
                            </p>
                            <Link to="/contact">
                              <span
                                className="text-white inline-block font-lora text-[18px] px-[20px] py-[12px] leading-none border-b-2 border-secondary bg-primary  mt-[40px] mb-[75px] hero_btn text-center"
                                style={{ marginLeft: "520px" }}
                              >
                                Explore
                              </span>
                            </Link>
                            <div className="col-span-12 xl:col-span-10">
                              <ul className="flex flex-wrap items-center sm:justify-between text-white mt-[-20px] mb-[10px] lg:mb-[60px]">
                                <li className="pr-[30px] sm:pr-[35px] lg:pr-[70px] sm:border-r sm:border-[#E0E0E0] my-[20px]"></li>
                                <li className="pr-[30px] sm:pr-[35px] lg:pr-[70px] sm:border-r sm:border-[#E0E0E0] my-[20px]"></li>
                                <li className="pr-[30px] sm:pr-[35px] lg:pr-[70px] sm:border-r sm:border-[#E0E0E0] my-[20px]"></li>
                                <li className="pr-[30px] sm:pr-[35px] lg:pr-[70px] sm:border-r sm:border-[#E0E0E0] my-[20px]"></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-pagination home-pagination-six hidden lg:flex flex-wrap flex-col items-end"></div>
              </div>
            </div>
          </section>
        </SwiperSlide>
        {/* <SwiperSlide>
          <section className="hero-section bg-white">
            <div className="hero-slider2 overflow-hidden">
              <div className="swiper">
                <div className="swiper-wrapper">
                  <div
                    className="swiper-slide bg-no-repeat bg-left-bottom bg-sky-100 z-[1] relative before:absolute before:w-full before:h-full before:inset-0 before:content-[''] before:bg-[#000000] before:opacity-[75%] before:z-[-1] py-[80px] lg:py-[125px]"
                    style={{
                      backgroundImage: 'url("assets/images/hero/home-6.1.png")',
                    }}
                  >
                    <div className="container">
                      <div className="grid grid-cols-12">
                        <div className="col-span-12">
                          <div className="slider-content">
                            <div className="relative mb-5 sub_title"></div>
                            <h1 className="font-lora text-white text-[36px] sm:text-[50px] md:text-[68px] lg:text-[50px] leading-tight xl:text-2xl title">
                              <span>Patriot de Plaza</span>
                            </h1>
                            <p className="text-base text-white font-light underline text">
                              welcome to the future
                            </p>
                            <Link to="/">
                              <span
                                className="text-white inline-block font-lora text-[18px] px-[20px] py-[12px] leading-none border-b-2 border-secondary bg-primary  mt-[40px] mb-[75px] hero_btn text-center"
                                style={{ marginLeft: "520px" }}
                              >
                                Explore
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-pagination home-pagination-six hidden lg:flex flex-wrap flex-col items-end"></div>
              </div>
            </div>
          </section>
        </SwiperSlide> */}
      </Swiper>
    </>
  );
}
