import React from "react";
import { Link } from "react-router-dom";

const TypeSales = () => {
  return (
    <>
      <section className="featured-properties py-[80px] lg:py-[120px] bg2 ">
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12"></div>
            <div className="col-span-12 flex flex-wrap flex-col md:flex-row items-start justify-between mb-[50px]">
              <div className="mb-5 lg:mb-0">
                <h2 className="  font-lora text-primary text-[24px] sm:text-[30px] xl:text-xl capitalize font-medium">
                  Gallery
                  <span className="text-secondary"></span>
                </h2>
              </div>
            </div>
            <div className="col-span-12">
              <div className="all-properties properties-tab-content active">
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-[30px]">
                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <Link to="/gallery" className="block">
                          <img
                            src="assets/images/properties/Flat.png"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Elite Garden Resedence."
                          />
                        </Link>
                        <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">
                          Builder share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginLeft: "103px" }}
                        >
                          Landlord share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginLeft: "218px" }}
                        >
                          Investment share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginBottom: "40px" }}
                        >
                          Sale/Resale
                        </span>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <Link
                            to="/gallery"
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Flat/apartments
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <Link to="/gallery" className="block">
                          <img
                            src="assets/images/properties/villas1.jpg"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Sinomen Studio Palace."
                          />
                        </Link>
                        <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">
                          Builder share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginLeft: "103px" }}
                        >
                          Landlord share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginLeft: "218px" }}
                        >
                          Investment share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginBottom: "40px" }}
                        >
                          Resale
                        </span>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <Link
                            to="/gallery"
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Villas
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <Link to="/gallery" className="block">
                          <img
                            src="assets/images/properties/studio rooms.jpg"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Sinomen Studio Palace."
                          />
                        </Link>
                        <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">
                          Sale/Resale
                        </span>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <Link
                            to="/gallery"
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Studio Rooms
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <Link to="/gallery" className="block">
                          <img
                            src="assets/images/properties/Independent House1.jpg"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Sinomen Studio Palace."
                          />
                        </Link>
                        <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">
                          Builder share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginLeft: "103px" }}
                        >
                          Landlord share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginLeft: "218px" }}
                        >
                          Investment share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginBottom: "40px" }}
                        >
                          Sale/Resale
                        </span>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <Link
                            to="/gallery"
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Independent House
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <Link to="/gallery" className="block">
                          <img
                            src="assets/images/properties/hotel.jpg"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Orchid Casel de Paradise."
                          />
                        </Link>
                        <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">
                          Sale/Resale
                        </span>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <Link
                            to="/gallery"
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Hotel/Resorts
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <Link to="/gallery" className="block">
                          <img
                            src="assets/images/properties/Flat.jpg"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="De Parasiya Appartment."
                          />
                        </Link>
                        <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">
                          Sale/Resale
                        </span>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <Link
                            to="/gallery"
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Service Apartments
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <Link to="/gallery" className="block">
                          <img
                            src="assets/images/properties/properties2.png"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Sinomen Studio Palace."
                          />
                        </Link>
                        <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">
                          Builder share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginLeft: "103px" }}
                        >
                          Landlord share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginLeft: "218px" }}
                        >
                          Sale/Resale
                        </span>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <Link
                            to="/gallery"
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Farm Houses
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <Link to="/gallery" className="block">
                          <img
                            src="assets/images/properties/Warehouse.jpg"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Duplex de Villa"
                          />
                        </Link>
                        <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">
                          Sale/Resale
                        </span>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <Link
                            to="/gallery"
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            Warehouse
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="overflow-hidden rounded-md drop-shadow-[0px_0px_5px_rgba(0,0,0,0.1)] bg-[#FFFDFC] text-center transition-all duration-300 hover:-translate-y-[10px]">
                      <div className="relative">
                        <Link to="/gallery" className="block">
                          <img
                            src="assets/images/properties/2bhk.png"
                            className="w-full h-full"
                            loading="lazy"
                            width={370}
                            height={266}
                            alt="Ruposibangla de parlosia."
                          />
                        </Link>
                        <span className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize">
                          Builder share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginLeft: "103px" }}
                        >
                          Landlord share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginLeft: "218px" }}
                        >
                          Investment share
                        </span>
                        <span
                          className="absolute bottom-5 left-5 bg-[#FFFDFC] p-[5px] rounded-[2px] text-primary leading-none text-[14px] font-normal capitalize"
                          style={{ marginBottom: "40px" }}
                        >
                          Sale/Resale
                        </span>
                      </div>
                      <div className="py-[20px] px-[20px] text-left">
                        <h3>
                          <Link
                            to="/gallery"
                            className="font-lora leading-tight text-[22px] xl:text-[26px] text-primary hover:text-secondary transition-all font-medium"
                          >
                            2bhk/3bhk/4bhk & 5bhk
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TypeSales;
