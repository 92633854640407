import React from "react";

const Location = () => {
  return (
    <>
      <section
        className="bg-no-repeat bg-center bg-cover bg-[#E9F1FF] h-[350px] lg:h-[513px] flex flex-wrap items-center relative before:absolute before:inset-0 before:content-[''] before:bg-[#000000] before:opacity-[70%]"
        style={{
          backgroundImage: 'url("assets/images/breadcrumb/bg-1.png")',
        }}
      >
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="max-w-[700px]  mx-auto text-center text-white relative z-[1]">
                <h1 className="font-lora text-[32px] sm:text-[50px] md:text-[68px] lg:text-[50px] leading-tight xl:text-2xl font-medium">
                  Our Location
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <div className="properties-grids-section pt-[80px] pb-[120px] lg:py-[120px]">
        <div className="container">
          <div className="max-w-[460px] mx-auto">
            <h2 className=" font-lora text-primary text-[24px] sm:text-[30px] leading-[1.277] xl:text-xl capitalize mb-3 font-medium">
              our<span className="text-secondary"> Location</span>
            </h2>
          </div>
          <div className="columns-1 sm:columns-2 md:columns-3 gap-x-[30px] mb-[-30px]">
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Gachibowli.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Homes"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Gachibowli
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Kondapur.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Villa"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Kondapur
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Kukatpally.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Appartments"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Kukatpally
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Madhapur.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Houses"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Madhapur
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Hi-tech city.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="duplex house"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Hi-tech city
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Miyapur.png"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Miyapur
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Bachupally.png"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Bachupally
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Patancheruvu.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Homes"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Patancheruvu
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Kondapur.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Villa"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Banjara Hills
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Mokila.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Villa"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Mokila
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Jubilee Hills.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Appartments"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Jubilee Hills
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Bandlaguda.png"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Houses"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Bandlaguda
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Kismatpur.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="duplex house"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Kismatpur
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Appa Junction.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Appa Junction
                    </span>
                  </div>
                </a>
              </div>
            </div>
            {/* <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/Nalagandla.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Nalagandla
                    </span>
                  </div>
                </a>
              </div>
            </div> */}
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/nannakramguda.jpeg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Nanakaramguda
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/finanacial district.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Financial District
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/nallagandla.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Nalagandla
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/kokapet.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Kokapet
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/narsingi.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Narsingi
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/gandipet.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Gandipet
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/tellapur.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Tellapur
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/gopanpally.jpg"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      GopanPally
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/kollur.png"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Kollur
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="mb-[30px]">
              <div className="relative group text-center">
                <a to="" className="block">
                  <img
                    src="assets/images/properties-grids/senkarpally.webp"
                    className="w-full h-full rounded-[6px]"
                    loading="lazy"
                    alt="Condos"
                  />
                  <div className="bg-[rgb(255,253,252,0.9)] max-w-[180px] rounded-[6px] px-[5px] py-[15px] absolute bottom-[25px] left-[25px] right-[25px]">
                    <span className="font-lora font-normal text-[18px] text-primary group-hover:text-secondary transition-all leading-none">
                      Shankarpalle
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
