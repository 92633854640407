import React from "react";
import BrandingCompaniesSlider from "./component/BrandingCompaniesSlider";

const BrandingCompanies = () => {
  return (
    <>
      <section className="py-[80px] lg:py-[120px]">
        <div className="container">
          <div className="max-w-[460px] mx-auto">
            <h2 className=" font-lora text-primary text-[24px] sm:text-[30px] leading-[1.277] xl:text-xl capitalize mb-3 font-medium">
              Brand<span className="text-secondary"> companies</span>
            </h2>
          </div>
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-[30px]">
            <div className="p-[25px] sm:p-[20px] lg:p-[25px] lg:pb-[30px] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] bg-[#FFFDFC] rounded-[5px] flex flex-col">
              <img
                className="self-center xl:mb-0 w-full"
                src="assets/images/brands/Rajapushpa.png"
                alt="brand logo"
              />
            </div>
            <div className="p-[25px] sm:p-[20px] lg:p-[25px] lg:pb-[30px] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] bg-[#FFFDFC] rounded-[5px] flex flex-col">
              <img
                className="self-center xl:mb-0 w-full"
                src="assets/images/brands/Vessella.png"
                alt="brand logo"
              />
            </div>
            <div className="p-[25px] sm:p-[20px] lg:p-[25px] lg:pb-[30px] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] bg-[#FFFDFC] rounded-[5px] flex flex-col">
              <img
                className="self-center xl:mb-0 w-full"
                src="assets/images/brands/Honer.png"
                alt="brand logo"
              />
            </div>
            <div className="p-[25px] sm:p-[20px] lg:p-[25px] lg:pb-[30px] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] bg-[#FFFDFC] rounded-[5px] flex flex-col">
              <img
                className="self-center xl:mb-0 w-full"
                src="assets/images/brands/Manjeera.png"
                alt="brand logo"
              />
            </div>

            <div className="p-[25px] sm:p-[20px] lg:p-[25px] lg:pb-[30px] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] bg-[#FFFDFC] rounded-[5px] flex flex-col">
              <img
                className="self-center xl:mb-0 w-full"
                src="assets/images/brands/Aparna.png"
                alt="brand logo"
              />
            </div>
            <div className="p-[25px] sm:p-[20px] lg:p-[25px] lg:pb-[30px] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] bg-[#FFFDFC] rounded-[5px] flex flex-col">
              <img
                className="self-center xl:mb-0 w-full"
                src="assets/images/brands/Legend chimes.png"
                alt="brand logo"
              />
            </div>
            <div className="p-[25px] sm:p-[20px] lg:p-[25px] lg:pb-[30px] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] bg-[#FFFDFC] rounded-[5px] flex flex-col">
              <img
                className="self-center xl:mb-0 w-full"
                src="assets/images/brands/my home.png"
                alt="brand logo"
              />
            </div>
            <div className="p-[25px] sm:p-[20px] lg:p-[25px] lg:pb-[30px] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] bg-[#FFFDFC] rounded-[5px] flex flex-col">
              <img
                className="self-center xl:mb-0 w-full"
                src="assets/images/brands/ramky.png"
                alt="brand logo"
              />
            </div>
            <div className="p-[25px] sm:p-[20px] lg:p-[25px] lg:pb-[30px] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] bg-[#FFFDFC] rounded-[5px] flex flex-col">
              <img
                className="self-center xl:mb-0 w-full"
                src="assets/images/brands/Smr.png"
                alt="brand logo"
              />
            </div>
            <div className="p-[25px] sm:p-[20px] lg:p-[25px] lg:pb-[30px] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] bg-[#FFFDFC] rounded-[5px] flex flex-col">
              <img
                className="self-center xl:mb-0 w-full"
                src="assets/images/brands/Jayabheri1.png"
                alt="brand logo"
              />
            </div>
            <div className="p-[25px] sm:p-[20px] lg:p-[25px] lg:pb-[30px] drop-shadow-[0px_2px_15px_rgba(0,0,0,0.1)] bg-[#FFFDFC] rounded-[5px] flex flex-col">
              <img
                className="self-center xl:mb-0 w-full"
                src="assets/images/brands/sumadhuragroup.png"
                alt="brand logo"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BrandingCompanies;
